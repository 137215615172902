import { addCustomerProperties, submitOptIn } from '../../api';
import { noop, promiseTry } from '../../lib';
import { identifyCustomer } from '../customer';

import type { JSONObject, PhoneNumber } from '@onetext/api';

type SubmitOptInOptions = {
    phone ?: PhoneNumber,
    customFlowParameters ?: JSONObject,
    customerProperties ?: Record<string, string>,
    email ?: string,
};

export const submitCustomerOptIn = ({
    phone,
    email,
    customerProperties,
    customFlowParameters
} : SubmitOptInOptions) : Promise<void> => {
    const customerPropertiesPromise = promiseTry(() => {
        if (phone && customerProperties) {
            return addCustomerProperties({
                phone,
                properties: customerProperties
            });
        }
    });

    const identifyCustomerPromise = promiseTry(() => {
        if (phone) {
            return identifyCustomer({
                phone
            });
        }
    });

    const submitOptInPromise = promiseTry(() => {
        return customerPropertiesPromise.then(() => {
            return submitOptIn({
                phone,
                email,
                customFlowParameters,
                requestTwoFactorCode: false,
                invokeCoreFlow:       true
            });
        });
    });

    return Promise.all([
        identifyCustomerPromise,
        customerPropertiesPromise,
        submitOptInPromise
    ]).then(noop);
};

import type { DOM_EVENT } from '../../constants';
import type { Cancelable } from '../../types';

import { listen } from '../util';

type ListenForElementEventsOptions = {
    selector : string,
    eventName : DOM_EVENT,
    filter ?: (input : HTMLElement) => boolean,
    rootElement ?: HTMLElement,
    onElement ?: (opts : {
        element : HTMLElement,
    }) => void,
    handler : (opts : {
        element : HTMLElement,
    }) => void,
};

export const listenForElementEvents = ({
    selector,
    eventName,
    filter,
    rootElement = document.body,
    onElement,
    handler
} : ListenForElementEventsOptions) : Cancelable => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!rootElement) {
        throw new Error(`Root element not found for listenForElementEvents`);
    }

    const listeners : Array<Cancelable> = [];

    const clearListeners = () : void => {
        for (const listener of listeners) {
            listener.cancel();
        }

        listeners.length = 0;
    };

    const observer = new MutationObserver(() => {
        clearListeners();
        const elements = Array.from(document.querySelectorAll(selector)) as ReadonlyArray<HTMLElement>;

        for (const element of elements) {
            if (filter && !filter(element)) {
                continue;
            }

            onElement?.({
                element
            });

            const listener = listen(element, eventName, (event : Event) : void => {
                if (event.target !== element) {
                    return;
                }

                handler({
                    element
                });
            });

            listeners.push(listener);
        }
    });

    observer.observe(rootElement, {
        childList: true,
        subtree:   true
    });

    return {
        cancel: () => {
            observer.disconnect();
            clearListeners();
        }
    };
};
